/*
* Login page
*/
body.login {
  background: #fcfcfc;
}
.login form:not(#language-switcher) {
  border: none !important;
  box-shadow: none !important;
}
#language-switcher {
  box-shadow: none !important;
}

/*# sourceMappingURL=main-login-register.css.map */
