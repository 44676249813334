@import "_variables";

/*
* Login page
*/

body.login {
  background: @admin-dashboard-bg;
}

.login form:not(#language-switcher) {

    border: none !important;
    box-shadow: none !important;
}

#language-switcher {
  box-shadow: none !important
}